import React, { useEffect, useState } from "react";
import {
  deleteReminder,
  getCalendarReminders,
} from "../../../api/calender/Calender";
import useAuth from "../../../hooks/useAuth";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  FiberManualRecord,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";

const HomeDailyReminders = () => {
  const { user } = useAuth();

  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const today = new Date();
  const date = today.toISOString().split("T")[0];

  useEffect(() => {
    // Retrieve visibility preference from localStorage
    const savedVisibility = localStorage.getItem("dailyRemindersVisibility");
    setIsVisible(savedVisibility !== "false");

    getReminders();
  }, []);

  const getReminders = async () => {
    try {
      const res = await getCalendarReminders(user.id, date);
      if (res?.data) {
        setReminders(res.data);
      } else {
        console.log("No reminders found for this date");
      }
    } catch (error) {
      console.error("Error fetching reminders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (reminderId) => {
    try {
      await deleteReminder(reminderId);
      // Remove the deleted reminder from the list
      setReminders((prevReminders) =>
        prevReminders.filter((reminder) => reminder.id !== reminderId)
      );
    } catch (error) {
      console.error("Error deleting reminder:", error);
    }
  };

  const toggleVisibility = () => {
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    // Save visibility preference to localStorage
    localStorage.setItem("dailyRemindersVisibility", newVisibility);
  };

  return (
    <Box mb={2} mt={3} sx={{ maxHeight: 400, overflowY: "auto" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="textPrimary">
          TODOs for Today
        </Typography>
        <IconButton onClick={toggleVisibility}>
          {isVisible ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </Box>
      {isVisible && (
        <>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : reminders.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              No TODO for today
            </Typography>
          ) : (
            <List sx={{ padding: 0 }}>
              {reminders.map((reminder) => (
                <ListItem
                  key={reminder.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #44444444",
                    padding: "8px 16px",
                    marginBottom: "0.5rem",
                    borderRadius: "8px",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#f4f4f4",
                      boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <FiberManualRecord
                    style={{
                      color: "#3f51b5",
                      fontSize: 14,
                      marginRight: 12,
                    }}
                    fontSize="small"
                  />
                  <ListItemText
                    primary={reminder.reminder}
                    primaryTypographyProps={{
                      variant: "body1",
                      style: {
                        fontWeight: 500,
                        color: "#333",
                      },
                    }}
                  />
                  {/* Delete Button */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(reminder.id)}
                    sx={{
                      color: "#f44336", // Red color for the delete icon
                      "&:hover": {
                        color: "#d32f2f", // Darker red on hover
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </Box>
  );
};

export default HomeDailyReminders;
