import {
  Avatar,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DeleteOutline, PersonAdd } from "@material-ui/icons";
import { deleteTask } from "../../api/project/Project";
import SprintDetailsActions from "./SprintDetailsActions";

export default function TaskCard({ item, refetchSprintTasks }) {
  return (
    <>
      <Card>
        <CardHeader
          title={item.name}
          subheader={`Task Points: ${item.points}`}
          titleTypographyProps={{
            variant: "caption",
            color: "black",
            fontWeight: 600,
          }}
          subheaderTypographyProps={{ variant: "caption", fontSize: 8 }}
          action={
            <ButtonGroup>
              <SprintDetailsActions
                refetchSprintTasks={refetchSprintTasks}
                sprintTask={item}
              />
              <IconButton
                size="small"
                sx={{ color: "red" }}
                onClick={async () => {
                  try {
                    const res = await deleteTask(item.id);
                    console.log(res);
                    await refetchSprintTasks();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <DeleteOutline color="inherit" fontSize="inherit" />
              </IconButton>
            </ButtonGroup>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar
            sx={{
              fontSize: "8px",
              width: "20px",
              height: "20px",
              color: "#333",
              fontWeight: "bold",
              background:
                "linear-gradient(180deg, #B31E4444 0%, #F7B31E44 100%)",
            }}
          >
            {item.assigned_to ? (
              item?.assigned_user?.first_name?.[0] +
              item?.assigned_user?.last_name?.[0]
            ) : (
              <PersonAdd sx={{ fontSize: "14px" }} />
            )}
          </Avatar>
          <Typography variant="caption" color="textSecondary" component="p">
            {!item.end_date
              ? "Not Provided"
              : new Date(item.end_date).toLocaleDateString("en-us", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
