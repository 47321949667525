import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";

// Duration calculation for each row in the table
const calculateDuration = (start, finish) => {
  const startDate = moment(start);
  const finishDate = moment(finish);
  return finishDate.diff(startDate, "days") + " days";
};

// Calculate completion percentage based on task status
const calculateCompletion = (status) => {
  const completionMapping = {
    backlog: 0,
    "to-dos": 25,
    "in-progress": 50,
    "q/a": 75,
    done: 100,
  };
  return completionMapping[status] || 0;
};

export default function Report() {
  const projectData = [
    {
      project: "Project ABC",
      sprints: [
        {
          sprint: "Sprint 1",
          tasks: [
            {
              name: "Task 1",
              priority: "Low",
              start: "2024-11-01",
              finish: "2024-11-05", // Green (Completed within time)
              status: "done",
            },
            {
              name: "Task 2",
              priority: "Medium",
              start: "2024-11-06",
              finish: moment().add(1, "days").format("YYYY-MM-DD"), // Yellow (Only 1 day remaining)
              status: "in-progress",
            },
            {
              name: "Task 3",
              priority: "Low",
              start: "2024-11-10",
              finish: moment().add(4, "days").format("YYYY-MM-DD"), // Blue (More than 2 days remaining)
              status: "to-dos",
            },
          ],
        },
        {
          sprint: "Sprint 2",
          tasks: [
            {
              name: "Task 1",
              priority: "High",
              start: "2024-11-01",
              finish: "2024-11-02", // Red (End date passed)
              status: "backlog",
            },
          ],
        },
      ],
    },
    {
      project: "Project Alpha",
      sprints: [
        {
          sprint: "Sprint 1",
          tasks: [
            {
              name: "Task 1",
              priority: "Low",
              start: "2024-11-01",
              finish: "2024-11-05",
              status: "done",
            },
            {
              name: "Task 2",
              priority: "Medium",
              start: "2024-11-06",
              finish: "2024-11-11",
              status: "in-progress",
            },
            {
              name: "Task 3",
              priority: "Low",
              start: "2024-11-12",
              finish: "2024-11-14",
              status: "to-dos",
            },
          ],
        },
        {
          sprint: "Sprint 2",
          tasks: [
            {
              name: "Task 1",
              priority: "High",
              start: "2024-11-18",
              finish: "2024-11-23",
              status: "q/a",
            },
            {
              name: "Task 2",
              priority: "Medium",
              start: "2024-11-24",
              finish: "2024-11-29",
              status: "backlog",
            },
            {
              name: "Task 3",
              priority: "Medium",
              start: "2024-12-24",
              finish: "2024-12-29",
              status: "backlog",
            },
          ],
        },
      ],
    },
    {
      project: "Project Beta",
      sprints: [
        {
          sprint: "Sprint 1",
          tasks: [
            {
              name: "Task 1",
              priority: "Medium",
              start: "2024-11-15",
              finish: "2024-11-19",
              status: "done",
            },
          ],
        },
      ],
    },
  ];

  const statusColors = {
    Red: "error",
    Yellow: "warning",
    Green: "success",
    Blue: "info",
  };

  const [selectedProject, setSelectedProject] = useState(null);

  const determineStatusColor = (status, finish) => {
    const today = moment();
    const finishDate = moment(finish);

    if (status === "done") {
      return "Green";
    } else if (today.isAfter(finishDate)) {
      return "Red";
    } else if (finishDate.diff(today, "days") <= 2) {
      return "Yellow";
    } else {
      return "Blue";
    }
  };

  const selectedProjectData = projectData.find(
    (project) => project.project === selectedProject
  );

  return (
    <Box p={3}>
      <Autocomplete
        options={projectData.map((project) => project.project)}
        value={selectedProject}
        onChange={(event, newValue) => setSelectedProject(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Select Project" variant="outlined" />
        )}
      />

      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sprint</TableCell>
              <TableCell>Tasks</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>% Complete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProjectData ? (
              selectedProjectData.sprints.map((sprintData, sprintIndex) => (
                <React.Fragment key={sprintIndex}>
                  <TableRow>
                    <TableCell
                      rowSpan={sprintData.tasks.length + 1}
                      style={{ fontWeight: "bold", backgroundColor: "#f9fafc" }}
                    >
                      {sprintData.sprint}
                    </TableCell>
                  </TableRow>
                  {sprintData.tasks.map((task, taskIndex) => (
                    <TableRow key={taskIndex}>
                      <TableCell>{task.name}</TableCell>
                      <TableCell>{task.priority}</TableCell>
                      <TableCell>{task.start}</TableCell>
                      <TableCell>{task.finish}</TableCell>
                      <TableCell>
                        {calculateDuration(task.start, task.finish)}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={determineStatusColor(task.status, task.finish)}
                          color={
                            statusColors[
                              determineStatusColor(task.status, task.finish)
                            ]
                          }
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>{calculateCompletion(task.status)}%</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Select a project to view sprint details
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
