import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { createReminder } from "../../../api/calender/Calender";
import moment from "moment";
import SavedReminders from "./SavedReminders";

const AddReminderModal = (props) => {
  const { date, showAddReminderModal, onClose, userId } = props;

  const [selectedTab, setSelectedTab] = useState(0); // State to track the selected tab

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog
      open={showAddReminderModal}
      onClose={() => onClose()}
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        minHeight: "400px",
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            textColor="inherit"
            sx={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "2px solid #7a4b8f",
              backgroundColor: "#7a4b8f",
              borderRadius: "8px",
              padding: "4px",
              width: "min-content",
            }}
          >
            <Tab
              label="Saved TODOs"
              sx={{
                backgroundColor: "#7a4b8f", // Purple background for tabs
                color: "#fff", // White text
                fontWeight: 600,
                borderRadius: "8px", // Rounded corners
                padding: "8px 20px", // Padding for button-like look
                "&:hover": {
                  backgroundColor: "#6a3b80", // Slightly darker purple on hover
                },
                "&.Mui-selected": {
                  backgroundColor: "#6a3b80", // Selected tab color
                },
              }}
            />
            <Tab
              label="Add TODO"
              sx={{
                backgroundColor: "#7a4b8f", // Purple background for tabs
                color: "#fff", // White text
                fontWeight: 600,
                borderRadius: "8px", // Rounded corners
                padding: "8px 20px", // Padding for button-like look
                "&:hover": {
                  backgroundColor: "#6a3b80", // Slightly darker purple on hover
                },
                "&.Mui-selected": {
                  backgroundColor: "#6a3b80", // Selected tab color
                },
              }}
            />
          </Tabs>
        </Box>

        {/* Show the corresponding content based on the selected tab */}
        <TabPanel value={selectedTab} index={0}>
          <SavedReminders
            userId={userId}
            date={moment(date).format("YYYY-MM-DD")}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Formik
            initialValues={{
              reminder: "",
            }}
            validationSchema={Yup.object().shape({
              reminder: Yup.string().required("TODO is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Perform your logic to save the reminder here
                const res = await createReminder({
                  user_id: userId,
                  reminder: values.reminder,
                  date: moment(date).format("YYYY-MM-DD"),
                });
                console.log(res.data);
                toast.success("Reminder added successfully");
                setSubmitting(false);
                onClose();
              } catch (err) {
                console.error(err);
                toast.error("Something went wrong");
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <DialogContent>
                  <TextField
                    label="TODO"
                    required
                    error={Boolean(touched.reminder && errors.reminder)}
                    fullWidth
                    helperText={touched.reminder && errors.reminder}
                    margin="normal"
                    name="reminder"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reminder}
                    variant="outlined"
                    rows={3}
                    multiline
                    sx={{ mt: 0 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => onClose()}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

// Helper component for displaying content based on selected tab index
function TabPanel(props) {
  const { value, index, children, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default AddReminderModal;
