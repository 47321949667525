import React, { useEffect, useState } from "react";
import {
  deleteReminder,
  getCalendarReminders,
} from "../../../api/calender/Calender"; // Assuming deleteReminder is an API function
import { FiberManualRecord, Delete as DeleteIcon } from "@material-ui/icons";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

const SavedReminders = ({ userId, date }) => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReminders();
  }, []);

  const getReminders = async () => {
    try {
      const res = await getCalendarReminders(userId, date);
      if (res?.data) {
        setReminders(res.data);
      } else {
        console.log("No reminders found for this date");
      }
    } catch (error) {
      console.error("Error fetching reminders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (reminderId) => {
    try {
      await deleteReminder(reminderId);
      // Remove the deleted reminder from the list
      setReminders((prevReminders) =>
        prevReminders.filter((reminder) => reminder.id !== reminderId)
      );
    } catch (error) {
      console.error("Error deleting reminder:", error);
    }
  };

  return (
    <Box mb={2} mt={3} sx={{ maxHeight: 400, overflowY: "auto" }}>
      {/* Display a loading spinner while fetching reminders */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : reminders.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No reminders found for this date.
        </Typography>
      ) : (
        <List sx={{ padding: 0 }}>
          {reminders.map((reminder) => (
            <ListItem
              key={reminder.id}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #44444444",
                padding: "8px 16px",
                marginBottom: "0.5rem",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f4f4f4",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                },
              }}
            >
              <FiberManualRecord
                style={{
                  color: "#3f51b5",
                  fontSize: 14,
                  marginRight: 12,
                }}
                fontSize="small"
              />
              <ListItemText
                primary={reminder.reminder}
                primaryTypographyProps={{
                  variant: "body1",
                  style: {
                    fontWeight: 500,
                    color: "#333",
                  },
                }}
              />
              {/* Delete Button */}
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(reminder.id)}
                sx={{
                  color: "#f44336", // Red color for the delete icon
                  "&:hover": {
                    color: "#d32f2f", // Darker red on hover
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default SavedReminders;
