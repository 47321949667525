import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  AccountTreeOutlined,
  AssignmentOutlined,
  ClearAll,
} from "@material-ui/icons";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Prompt, Link as RouterLink, useParams } from "react-router-dom";
import AddToTemplateModal from "../../components/dashboard/projects/AddToTemplateModal";
import AllTasks from "../../components/dashboard/projects/AllTasks";
import AllTasksBoardView from "../../components/dashboard/projects/AllTasksBoardView";
import AllTasksGanttChartView from "../../components/dashboard/projects/AllTasksGanttChartView";
import AllTasksTreeView from "../../components/dashboard/projects/AllTasksTreeView";
import MonthWiseBudget from "../../components/dashboard/projects/budget/MonthWiseBudget";
import Documents from "../../components/dashboard/projects/Documents";
import Issues from "../../components/dashboard/projects/Issues";
import Members from "../../components/dashboard/projects/Members";
import Milestones from "../../components/dashboard/projects/Milestones";
import ProjectDashboard from "../../components/dashboard/projects/ProjectDashboard";
import ProjectStageGraphic from "../../components/dashboard/projects/ProjectStageGraphic";
import ProjectStatus from "../../components/dashboard/projects/ProjectStatus";
import ProjectStatusGraphics from "../../components/dashboard/projects/ProjectStatusGraphics";
import Stages from "../../components/dashboard/projects/Stages";
import ViewAndEditProjectForm from "../../components/dashboard/projects/ViewAndEditProjectForm";
import PermissionGuard from "../../components/PermissionGuard";
import useAuth from "../../hooks/useAuth";
import useCommons from "../../hooks/useCommons";
import useProject from "../../hooks/useProject";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PencilAltIcon from "../../icons/PencilAlt";
import Sprint from "../sprint/Sprint";
import Sprints from "../sprint/Sprints";

const allTasksTab = [
  {
    label: "List",
    value: "listView",
    icon: <FormatListBulletedIcon className="chart-icon" />,
  },
  {
    label: "Board",
    value: "boardView",
    icon: <AssignmentOutlined className="chart-icon" />,
  },
  {
    label: "Tree",
    value: "treeView",
    icon: <AccountTreeOutlined className="chart-icon" />,
  },
  {
    label: "Gantt Chart",
    value: "ganttChatView",
    icon: <ClearAll className="chart-icon" />,
  },
];

const ViewAndEditProject = () => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const sprintId = parameters.get("sprintId");
  const activeTabParam = parameters.get("p");
  const activeTaskParam = parameters.get("t");
  const { permissions } = useAuth();

  const [selectedProject, setSelectedProject] = useState(null);
  const [editActive, setEditActive] = useState(false);
  const [currentTab, setCurrentTab] = useState(activeTabParam || "dashboard");
  const [activeAllTaskTab, setActiveAllTaskTab] = useState(
    activeTaskParam || "listView"
  );
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAddPhysicalDoumentModal, setShowAddPhysicalDoumentModal] =
    useState(false);
  const [showAddDoumentLinkModal, setShowAddDoumentLinkModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showAddMilestoneModal, setShowAddMilestoneModal] = useState(false);
  const [showAddIssueodal, setShowAddIssueodal] = useState(false);
  const [open, setOpen] = useState(false);
  const [tempTabValue, setTempTabValue] = useState(null);
  const [showImportTaskModal, setShowImportTaskModal] = useState(false);
  const [showAddToTemplate, setShowUseAddToTemplate] = useState(false);

  const { settings } = useSettings();

  const { projectId } = useParams();

  const { getSingleProject } = useProject();

  const { updateCurrentPath } = useCommons();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    try {
      const project = await getSingleProject(projectId);
      setSelectedProject(project);
    } catch (error) {
      console.log(error);
    }
  };

  const tabs = useMemo(() => {
    const data = [
      { label: "Dashboard", value: "dashboard", isVisible: true },
      { label: "Project Details", value: "projectDetails", isVisible: true },
      {
        label: "Sprints",
        value: "sprints",
        isVisible: selectedProject?.workflow_type === "agile",
      },
      {
        label: "Budget",
        value: "budget",
        isVisible: permissions["PROJECT_BUDGET_VIEW"],
      },
      {
        label: "Milestones",
        value: "milestones",
        isVisible: true,
        // isVisible: selectedProject?.workflow_type === "default",
      },
      { label: "Members", value: "members", isVisible: true },
      {
        label: "Task",
        value: "allTasks",
        isVisible: selectedProject?.workflow_type !== "agile",
      },
      { label: "Issues", value: "issues", isVisible: true },
      { label: "Documents", value: "documents", isVisible: true },
    ];
    return data;
  }, [selectedProject?.workflow_type, permissions["PROJECT_BUDGET_VIEW"]]);

  const handleTabsChange = (event, value) => {
    window.history.pushState(
      null,
      null,
      `/dashboard/projects/view-and-edit-project/${projectId}?p=${value}`
    );
    updateCurrentPath();
    if (editActive) {
      setTempTabValue(value);
      setOpen(true);
    } else {
      setCurrentTab(value);
    }
  };

  const handleTaskTabsChange = (value) => {
    window.history.pushState(
      null,
      null,
      `/dashboard/projects/view-and-edit-project/${projectId}?p=${currentTab}&t=${value}`
    );
    updateCurrentPath();
    setActiveAllTaskTab(value);
  };

  const resetFormAndRedirect = () => {
    if (tempTabValue) {
      setCurrentTab(tempTabValue);
      setEditActive(false);
      handleClose();
      setTempTabValue(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (function checkAndRedirect() {
      if (currentTab !== "dashboard") return;
      if (selectedProject === null) return;
      if (selectedProject.workflow_type === "agile") return;
      if (selectedProject?.taskCount !== 0) return;
      handleTabsChange(null, "projectDetails");
    })();
  }, [selectedProject, currentTab]);
  return (
    <>
      <Helmet>
        <title>Dashboard: View and Edit project</title>
      </Helmet>

      <Prompt
        when={editActive}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ pt: 3, maxWidth: "350px", textAlign: "center" }}
          >
            You might have unsaved changes, are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={resetFormAndRedirect} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AddToTemplateModal
        setShowUseAddToTemplate={setShowUseAddToTemplate}
        showAddToTemplate={showAddToTemplate}
        projectId={selectedProject?.id}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/dashboard/projects/view-all-projects"
                  variant="breadCrumbText"
                >
                  Projects View All
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  {selectedProject?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Card>
            <Box>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    pt: 0.5,
                  }}
                >
                  {[
                    { color: "#00F", label: "In Progress" },
                    { color: "#0F0", label: "Done" },
                    { color: "#FF0", label: "To Do" },
                    { color: "#F00", label: "Delay" },
                  ].map(({ color, label }) => (
                    <Fragment id={color}>
                      <Box
                        key={color}
                        sx={{
                          backgroundColor: color,
                          width: 10,
                          height: 10,
                          display: "inline-block",
                          marginRight: 0.5,
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          display: "inline-block",
                          marginRight: 3,
                          fontSize: "14px",
                        }}
                      >
                        {label}
                      </Typography>
                    </Fragment>
                  ))}
                </Box>
              </Box>
              <Box sx={{ borderBottom: "1px solid #D7DFEC" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 3,
                    height: "100px",
                    pt: 1,
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ProjectStatusGraphics
                        selectedProject={selectedProject}
                        getProject={getProject}
                      />
                      <Typography
                        variant="dashboardContentHeader"
                        sx={{
                          color: "#2D3E56",
                          display: "block",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedProject?.name}
                      </Typography>
                    </Box>
                    <Typography sx={{ pl: 2, fontSize: "12px" }}>
                      {selectedProject?.status === 3
                        ? "Delayed"
                        : selectedProject?.status === 2
                        ? "Completed"
                        : "WIP"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowUseAddToTemplate(true);
                      }}
                      sx={{ mr: 2 }}
                    >
                      Save As Template
                    </Button>
                    {currentTab === "projectDetails" && (
                      <PermissionGuard
                        permissionCheck="PROJECT_UPDATE"
                        returnEmpty={true}
                      >
                        <Box>
                          {!editActive && (
                            <Button
                              variant="contained"
                              startIcon={<PencilAltIcon />}
                              onClick={() => {
                                setEditActive(true);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </Box>
                      </PermissionGuard>
                    )}
                    {currentTab === "milestones" && (
                      <Box>
                        {!editActive && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setShowAddMilestoneModal(true);
                            }}
                          >
                            Add New Milestone
                          </Button>
                        )}
                      </Box>
                    )}
                    {currentTab === "issues" && (
                      <Box>
                        {!editActive && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setShowAddIssueodal(true);
                            }}
                          >
                            Add New Issue
                          </Button>
                        )}
                      </Box>
                    )}
                    {currentTab === "allTasks" && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {allTasksTab.map((tab, index) => (
                          <Box
                            key={index}
                            onClick={() => handleTaskTabsChange(tab.value)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 1,
                              cursor: "pointer",
                              color:
                                activeAllTaskTab !== tab.value
                                  ? "#89969F"
                                  : "#222",

                              ".chart-icon": {
                                color:
                                  activeAllTaskTab !== tab.value
                                    ? "#89969F"
                                    : "#222",
                              },
                            }}
                          >
                            {tab.icon}
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "14px",
                                fontWeight:
                                  activeAllTaskTab !== tab.value
                                    ? "500"
                                    : "bold",
                              }}
                            >
                              {tab.label}
                            </Typography>
                          </Box>
                        ))}
                        <Box sx={{ ml: 1 }}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => setShowAddTaskModal(true)}
                          >
                            + TASK
                          </Button>
                        </Box>
                        {activeAllTaskTab === "treeView" && (
                          <Box sx={{ ml: 2 }}>
                            <Button
                              color="primary"
                              variant="outlined"
                              size="large"
                              onClick={() => setShowAddMilestoneModal(true)}
                            >
                              + MILESTONE
                            </Button>
                          </Box>
                        )}
                        {activeAllTaskTab !== "boardView" && (
                          <Box sx={{ ml: 2 }}>
                            <Button
                              variant="outlined"
                              size="large"
                              onClick={() => setShowImportTaskModal(true)}
                              sx={{
                                backgroundColor: "#092bb1",
                                color: "#fff",
                                ":hover": {
                                  backgroundColor: "#071a62",
                                },
                              }}
                            >
                              IMPORT
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}

                    {currentTab === "members" && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => setShowAddMemberModal(true)}
                      >
                        ADD NEW TEAM MEMBER
                      </Button>
                    )}

                    {currentTab === "documents" && (
                      <Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => setShowAddDoumentLinkModal(true)}
                        >
                          ADD DOCUMENT LINK
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => setShowAddPhysicalDoumentModal(true)}
                          sx={{ ml: 1 }}
                        >
                          ADD PHYSICAL DOCUMENT
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
                <ProjectStageGraphic
                  selectedProject={selectedProject}
                  getProject={getProject}
                />
                <Box>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                    sx={{
                      ".MuiTab-wrapper": {
                        textTransform: "none",
                        fontSize: "12px",
                      },
                      ".Mui-selected": {
                        fontWeight: "bold",
                        color: "#000",
                      },
                      button: {
                        minWidth: "80px",
                        "&:hover": {
                          backgroundColor: "#eee",
                        },
                      },
                      ".MuiTabs-indicator": {
                        minWidth: "60px",
                      },
                      px: 2,
                    }}
                  >
                    {tabs
                      ?.filter((_) => _.isVisible)
                      .map((tab) => (
                        <Tab
                          key={tab.value}
                          label={tab.label}
                          value={tab.value}
                        />
                      ))}
                  </Tabs>
                </Box>
              </Box>
              {selectedProject && (
                <Box sx={{ mt: 1, px: 3, py: 2 }}>
                  {currentTab === "projectDetails" && (
                    <ViewAndEditProjectForm
                      selectedProject={selectedProject}
                      editActive={editActive}
                      setEditActive={setEditActive}
                      currentTab={currentTab}
                      getProject={getProject}
                    />
                  )}
                  {currentTab === "allTasks" &&
                    (activeAllTaskTab === "listView" ? (
                      <AllTasks
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ) : activeAllTaskTab === "boardView" ? (
                      <AllTasksBoardView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                      />
                    ) : activeAllTaskTab === "treeView" ? (
                      <AllTasksTreeView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showAddMilestoneModal={showAddMilestoneModal}
                        setShowAddMilestoneModal={setShowAddMilestoneModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ) : (
                      <AllTasksGanttChartView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ))}
                  {currentTab === "sprints" &&
                    (sprintId ? (
                      <Sprint sprintId={sprintId} />
                    ) : (
                      <Sprints
                        onlySprint
                        onListClick={(sprintId) => {
                          window.history.pushState(
                            null,
                            null,
                            `/dashboard/projects/view-and-edit-project/${projectId}?p=${currentTab}&sprintId=${sprintId}`
                          );
                          updateCurrentPath();
                          setActiveAllTaskTab("sprints");
                        }}
                      />
                    ))}

                  {currentTab === "stages" && (
                    <Stages selectedProject={selectedProject} />
                  )}
                  {currentTab === "dashboard" && (
                    <ProjectDashboard selectedProject={selectedProject} />
                  )}
                  {currentTab === "budget" && (
                    <PermissionGuard permissionCheck="PROJECT_BUDGET_VIEW">
                      <MonthWiseBudget
                        selectedProject={selectedProject}
                        getProject={getProject}
                      />
                    </PermissionGuard>
                  )}
                  {/* {currentTab === "score" && (
                    <Score selectedProject={selectedProject} />
                  )} */}
                  {currentTab === "projectStatus" && (
                    <ProjectStatus selectedProject={selectedProject} />
                  )}
                  {currentTab === "members" && (
                    <Members
                      selectedProject={selectedProject}
                      showAddMemberModal={showAddMemberModal}
                      setShowAddMemberModal={setShowAddMemberModal}
                    />
                  )}
                  {currentTab === "milestones" && (
                    <Milestones
                      selectedProject={selectedProject}
                      showAddMilestoneModal={showAddMilestoneModal}
                      setShowAddMilestoneModal={setShowAddMilestoneModal}
                    />
                  )}
                  {currentTab === "issues" && (
                    <Issues
                      setShowAddIssueodal={setShowAddIssueodal}
                      showAddIssueodal={showAddIssueodal}
                      selectedProject={selectedProject}
                    />
                  )}
                  {/* {currentTab === "measure" && (
                    <Measure selectedProject={selectedProject} />
                  )} */}
                  {currentTab === "documents" && (
                    <Documents
                      selectedProject={selectedProject}
                      setShowAddPhysicalDoumentModal={
                        setShowAddPhysicalDoumentModal
                      }
                      showAddPhysicalDoumentModal={showAddPhysicalDoumentModal}
                      setShowAddDoumentLinkModal={setShowAddDoumentLinkModal}
                      showAddDoumentLinkModal={showAddDoumentLinkModal}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ViewAndEditProject;
