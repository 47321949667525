import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useState } from "react";
import { handleApiRequest } from "../../hooks/callApi";

export default function EditSprint({ sprint }) {
  const [open, setOpen] = useState(false);
  const [sprintData, setSprintData] = useState({
    name: sprint.name,
    goal: sprint.goal,
    start_date: sprint.start_date,
    end_date: sprint.end_date,
    project_id: sprint.project_id,
  });

  const [errors, setErrors] = useState({
    start_date: "",
    end_date: "",
  });

  const handleInputChange = (e) => {
    setSprintData({
      ...sprintData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveSprint = async () => {
    // Reset errors
    setErrors({
      start_date: "",
      end_date: "",
    });

    let hasError = false;
    const { start_date, end_date } = sprintData;

    if (new Date(start_date) > new Date(end_date)) {
      setErrors((prev) => ({
        ...prev,
        end_date: "End date must be after start date",
      }));
      hasError = true;
    }

    if (new Date(end_date) < new Date()) {
      setErrors((prev) => ({
        ...prev,
        end_date: "End date cannot be in the past",
      }));
      hasError = true;
    }

    if (!hasError) {
      // Perform validation and save the sprint
      console.log("Updating sprint:", sprintData);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/sprints/${sprint.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
          body: JSON.stringify(sprintData),
        }
      );
      console.log(response);
      const data = await response.json();
      console.log(data);
      setOpen(false);
    }
  };

  return (
    <>
      <Tooltip title="Edit Sprint">
        <IconButton onClick={() => setOpen(true)} size="small">
          <Edit fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Sprint</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Sprint Name"
                name="name"
                value={sprintData.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sprint Goal"
                name="goal"
                value={sprintData.goal}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                name="start_date"
                type="date"
                value={sprintData.start_date}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                error={!!errors.start_date}
                helperText={errors.start_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                name="end_date"
                type="date"
                value={sprintData.end_date}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                error={!!errors.end_date}
                helperText={errors.end_date}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveSprint}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
