import React, { useEffect } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import moment from "moment";
import { handleApiRequest } from "../../../hooks/callApi";

const GanttChart = ({ data }) => {
  useEffect(() => {
    // Set up Gantt chart configuration
    gantt.config.xml_date = "%Y-%m-%d %H:%i";

    gantt.config.columns = [
      { name: "text", label: "Task Name", width: "*", align: "left" },
      { name: "start_date", label: "Start Date", align: "center" },
      { name: "end_date", label: "End Date", align: "center" },
    ];

    // Initialize the Gantt chart
    gantt.init("sprint-gantt-container");

    // Transform the data to match DHTMLX Gantt format
    const tasks = data.map((sprint) => ({
      id: sprint.id,
      text: sprint.name,
      project_id: sprint.project_id,
      start_date: moment(sprint.start_date).format("YYYY-MM-DD"),
      end_date: moment(sprint.end_date).format("YYYY-MM-DD"),
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    }));

    // Parse the data into the Gantt chart
    gantt.parse({ data: tasks });

    // Attach Task Events

    // Before task delete (e.g., confirmation)
    gantt.attachEvent("onBeforeTaskDelete", async (id, task) => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/sprints/${id}`;
      const res = await fetch(url, { method: "DELETE" });
      const data = await res.json();
      console.log({ data });
      return true; // Continue with the delete
    });

    gantt.attachEvent("onAfterTaskUpdate", async (id, task) => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/sprints/${id}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: task.text,
          project_id: task.project_id,
          start_date: moment(task.start_date).format("YYYY-MM-DD"),
          end_date: moment(task.end_date).format("YYYY-MM-DD"),
        }),
      });
      const data = await res.json();
      console.log({ data });
      return true;
    });

    // Cleanup event listeners when component unmounts
    return () => {
      gantt.clearAll();
    };
  }, [data]);

  return (
    <div
      id="sprint-gantt-container"
      style={{ width: "100%", height: "500px", overflowY: "auto" }}
    />
  );
};

export default GanttChart;
