import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import { handleApiRequest } from "../../hooks/callApi";
import TaskCard from "./TaskCard";
import { editTask } from "../../api/project/Project";

export default function SprintBoard({ refreshCount, isColumn, sprintId }) {
  const { projectId } = useParams();
  const { data: projectMembersRes } = useFetch(
    `/api/project/members/${projectId}`
  );

  const { data: sprintTasksStatuses } = useFetch(`/api/sprint-task-statuses`);

  const { data: projectTasksResponse, refetch: refetchProjectTasks } = useFetch(
    `/api/project/get-all-task/${projectId}?sprint_id=${sprintId}`
  );
  const projectTasks = projectTasksResponse?.data?.tasks || [];

  useEffect(() => {
    if (refreshCount) refetchProjectTasks();
  }, [refreshCount]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const updatedTasks = [...projectTasks];
    const movedTask = updatedTasks.find(
      (task) => task.id === parseInt(draggableId)
    );
    const updatedTask = {
      task_name: movedTask.name,
      proj_id: movedTask.proj_id,
      start_date: movedTask.start_date,
      end_date: movedTask.end_date,
      person_id: movedTask.assigned_to,
      task_id: movedTask.id,
      task_status: sprintTasksStatuses?.data?.find(
        (_) => _.status === destination.droppableId
      )?.id,
    };
    if (movedTask) {
      await editTask(updatedTask);
      await refetchProjectTasks();
    }
  };

  return (
    <section
      className={`sprint-board ${
        isColumn ? "sprint-board-column" : "sprint-board-row"
      }`}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="backlog">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks backlog">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Backlog
                </Typography>
                <ul className="slots">
                  {projectTasks
                    ?.filter((task) => task.status === 1)
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchProjectTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="to-dos">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks to-dos">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  To Dos
                </Typography>
                <ul className="slots">
                  {projectTasks
                    ?.filter((task) => task.status === 2)
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchProjectTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="in-progress">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks in-progress">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  In Progress
                </Typography>
                <ul className="slots">
                  {projectTasks
                    ?.filter((task) => task.status === 3)
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchProjectTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="q/a">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks q-a">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Q/A
                </Typography>
                <ul className="slots">
                  {projectTasks
                    ?.filter((task) => task.status === 4)
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchProjectTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="done">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks done">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Done
                </Typography>
                <ul className="slots">
                  {projectTasks
                    ?.filter((task) => task.status === 5)
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchProjectTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
}
